/* 整个博客部分容器 */
.blog-list {
  max-width: 85%; /* 调整内容宽度，更靠左 */
  margin: 0 auto; /* 居中对齐 */
  padding: 2rem 0; /* 上下间距 */
}

/* 每个帖子容器 */
.blog-post {
  display: flex; /* 使用 Flexbox 布局 */
  gap: 10px; /* 图片和文字之间的间距 */
}

/* 文字部分 */
.blog-text {
  flex-grow: 1; /* 文字部分动态填充剩余空间 */
}

.blog-title {
  font-size: 2rem; /* 标题大小 */
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 1.4; /* 行高更易读 */
}

.blog-title-link {
  color: #f9602e; /* 设置标题为黑色 */
  text-decoration: none; /* 移除下划线 */
}

.blog-title-link:hover {
  color: gray; /* 鼠标悬停时变成灰色（可选） */
  text-decoration: none; /* 悬停时也不显示下划线 */
}

.blog-description {
  font-size: 1.2rem; /* 描述部分大小 */
  margin-bottom: 1.5rem;
  color: #333; /* 深灰色文字 */
}

.blog-meta {
  font-size: 1rem; /* 时间字体较小 */
  color: #6c757d; /* 浅灰色 */
}

/* 图片部分 */
.blog-image-container {
  flex-shrink: 0; /* 防止图片被压缩 */
}

.blog-image {
  max-width: 500px; /* 增加图片宽度 */
  height: auto; /* 保持比例 */
  max-height: 500px; /* 限制最大高度 */
  object-fit: cover; /* 裁剪图片内容 */
}

/* Responsive styles for small screens */
@media (max-width: 768px) {
  .blog-post {
    flex-direction: column; /* 改为垂直布局 */
    gap: 20px; /* 增加间距 */
    align-items: center; /* 居中内容 */
  }

  .blog-text {
    text-align: center; /* 文字居中 */
  }

  .blog-title {
    font-size: 1.8rem; /* 小屏幕调整标题大小 */
  }

  .blog-description {
    font-size: 1rem; /* 小屏幕时字体变小 */
    margin-bottom: 1rem;
  }

  .blog-meta {
    font-size: 0.9rem; /* 小屏幕缩小字体 */
  }

  .blog-image-container {
    order: -1; /* 将图片移到最前面 */
  }

  .blog-image {
    max-width: 100%; /* 小屏幕时宽度适应屏幕 */
    max-height: 300px; /* 调整最大高度 */
  }
}
