// Custom variable overrides
// See node_modules/bootstrap/scss/_variables.scss

// $body-bg: black;
// $body-color: white;
// $link-hover-color: grey;
// $font-family-sans-serif: "Helvetica Neue";

/* bootstrap */
// Option A: Include all of Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// Option B: Include parts of Bootstrap
// See https://getbootstrap.com/docs/4.1/getting-started/theming/#importing

// Required
// @import "../../node_modules/bootstrap/scss/functions";
// @import "../../node_modules/bootstrap/scss/variables";
// @import "../../node_modules/bootstrap/scss/mixins";

// Import partials
@import 'nav';
@import 'index';
@import 'works';
@import 'musicapp';
@import 'more';
@import 'bake';
@import 'blog';

h3 {
  font-size: 1.5rem;
  font-family: 'Inconsolata', monospace;
}

h1 {
  font-family: 'Inconsolata', monospace;
  font-weight: 400;
}

p {
  font-family: 'Lora', serif;
  font-weight: 300;
}

.container-fluid {
  max-width: 80%;
  padding-right: 0;
  padding-left: 0;
  margin-top: 20px;
}

footer {
  position: sticky; /* 保证 footer 跟随页面内容，但固定在底部 */
  bottom: 0; /* 将 footer 定位到视窗底部 */
  width: 100%; /* 占据全宽 */
  padding: 20px 0; /* 上下内边距 */
  text-align: center; /* 文本居中 */
  border-top: 1px solid #e9ecef; /* 增加顶部边框分隔 */
}
