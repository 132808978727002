.more-content {
  max-width: 800px; /* Control content width */
  margin: 0 auto; /* Center the content */
  padding: 2rem;

  /* Section title styling */
  .section-title {
    font-family: 'Inconsolata', monospace;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 2rem;
    text-align: left;
  }

  /* Content section for Latest and In the News */
  .content-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* Space between each category section */

    /* Grouping for each category */
    .category-group {
      display: flex;
      flex-direction: column;
    }

    /* Category title */
    .category-title {
      font-weight: bold;
      font-size: 1rem;
      color: #333;
      text-align: left;
      margin-bottom: 0.5rem; /* Add space below the title */
    }

    /* Category content */
    .category-content {
      font-size: 0.9rem;
      color: #666;
      line-height: 1.6;

      p {
        margin-bottom: 1rem; /* Add spacing between paragraphs */
      }

      .text-link {
        color: #f1592b;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  /* "In the News" section styling */
  .cite-section {
    margin-top: 2rem;

    .content-section {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      p {
        font-size: 0.9rem;
        color: #444;
        line-height: 1.6;
        margin-bottom: 0.5rem;
      }

      .text-link {
        font-weight: bold;
        color: #f1592b;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .more-content {
    padding: 1.5rem;

    .section-title {
      font-size: 1.5rem;
    }

    .category-title {
      font-size: 1.3rem;
    }

    .category-content,
    .cite-section p {
      font-size: 1.1rem;
    }
  }
}
