.container {
  max-width: 1200px; /* 限制容器宽度 */
  margin: 0 auto; /* 居中 */
  padding: 40px 20px; /* 上下内边距 */
}

.row {
  display: flex; /* Flexbox 布局 */
  align-items: center; /* 垂直居中对齐 */
}

.profile-picture {
  max-width: 100%; /* 图片宽度自适应 */
  height: auto; /* 保持图片比例 */
  border-radius: 8px; /* 圆角效果（可选） */
  margin-bottom: 10px; /* 图片与文字的间距 */
}

.photo-credit {
  font-size: 14px;
  color: #666; /* 浅灰色 */
  margin-top: 10px;
  text-align: center;
  font-style: italic;
}

.intro {
  font-family: 'Inconsolata', monospace;
  line-height: 1.8; /* 行间距 */
  font-size: 18px; /* 字体大小 */
}

.intro p {
  margin-bottom: 20px; /* 每段文字之间的间距 */
}

.link {
  color: black;
  text-decoration: none;
  position: relative;
  display: inline-block; /* 让 a 能撑开大小，保证 ::after 生效 */
  transition: background-color 0.2s ease-in-out;
}

/* 默认状态：底部只显示一条高亮条 */
.link::after {
  content: '';
  position: absolute;
  z-index: 2;
  left: -0.1em;
  right: -0.1em;
  top: 66%; /* 初始状态，底部三分之一 */
  height: 28%; /* 确保矩形大小是 33% 左右 */
  background-color: rgba(79, 192, 141, 0.5);
  transition: top 200ms cubic-bezier(0, 0.8, 0.13, 1),
    height 200ms cubic-bezier(0, 0.8, 0.13, 1);
}

/* 悬停时：底部高亮扩展到整个文本 */
.link:hover::after {
  top: 0;
  height: 100%;
}

/* 点击时：背景填满并且变得更圆 */
.link:active {
  background-color: rgba(79, 192, 141, 0.8);
  border-radius: 6px;
}

/* 确保 p 不会隐藏 ::after */
.intro p {
  overflow: visible !important;
}

#typewriter {
  font-size: 1.3 rem; /* Larger font size for the first sentence */
  font-family: 'Inconsolata', monospace;
  margin-bottom: 20px; /* Add spacing below */
  animation: typewriter 4s steps(40, end), blink 0.7s step-end infinite;
}

/* Keyframes for typewriter effect */
@keyframes typewriter {
  from {
    clip-path: inset(0 100% 0 0); /* 使用 clip-path 遮挡 */
  }
  to {
    clip-path: inset(0 0 0 0); /* 完全显示 */
  }
}

/* Keyframes for blinking cursor */
@keyframes blink {
  50% {
    border-color: transparent;
  }
}

/* Add responsive styles as needed */
@media (max-width: 768px) {
  .image-container,
  .text-container {
    text-align: center; /* Center elements on smaller screens */
  }
  .btn-primary {
    margin-top: 20px; /* Add spacing above the button on smaller screens */
  }
  #typewriter {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}
