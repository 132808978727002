// Define variable for medium size and spacing
$medium-width: 50%; // Adjust the size as needed
$vertical-spacing: 10px; // Reduced spacing for a more compact layout

// Apply styles to all images
img {
  display: block; // Ensures the image is treated as a block-level element
  width: $medium-width; // Set width to medium
  height: auto; // Maintain aspect ratio
  margin-top: $vertical-spacing; // Reduced top margin for compactness
  margin-bottom: $vertical-spacing; // Reduced bottom margin for compactness
  margin-left: auto; // Centers the image horizontally
  margin-right: auto; // Centers the image horizontally
}

.story-container {
  max-width: 1000px; // 页面内容的最大宽度
  margin: 0 auto; // 居中内容
  padding: 20px; // 内边距
}

/* Add responsive styles as needed */
@media (max-width: 768px) {
  .image-container,
  .text-container {
    text-align: center; /* Center elements on smaller screens */
  }
  .btn-primary {
    margin-top: 10px; /* Reduce spacing above the button for a more compact layout */
  }
}
