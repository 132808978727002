// 全局内容区域通用样式
.content-sections {
  max-width: 1500px; // 设置最大宽度
  margin: 0 auto; // 居中对齐
  padding: 0 2rem;

  @media (min-width: 768px) {
    padding: 1rem 9rem 0.5rem 6rem;
  }

  h2 {
    margin: 2rem 0; // 标题上下边距
    font-family: 'Inconsolata', monospace;
    font-size: 1.5rem;
    color: #333;
  }
}

// 卡片统一样式（应用于所有内容部分）
.card {
  border: none;
  overflow: hidden;
  border-radius: 15px; // 圆角
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);

  min-height: 300px;
  max-height: 300px;

  @media (max-width: 768px) {
    min-height: 250px;
  }

  &.card-has-bg {
    background-size: 75%; // 背景图比例
    background-position: center center; // 背景图位置
    background-repeat: no-repeat;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(247, 247, 233, 0.4); // 米色背景，透明度 70%
      z-index: 1; // 确保在背景图之上
    }

    &:hover {
      transform: scale(0.98); // 缩放动画
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3); // 添加阴影动效
    }

    .card-body {
      font-family: 'Inconsolata', sans-serif;
      color: #333;
      z-index: 2; // 确保文字内容在背景之上
      position: relative;

      .card-title {
        font-size: 1rem; // 标题字体大小
        font-weight: 700;
      }

      .card-meta {
        color: #000; // publication 字体颜色
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1.5px;
      }
    }
  }
}

// 特定内容部分继承通用样式和卡片效果
.works-intro,
.data-graphics,
.cartography-projects,
.interaction-design,
.writing-notes {
  @extend .content-sections;

  .card {
    @extend .card;
  }
}
